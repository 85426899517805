<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="发运"
    size="1111px"
  >
    <el-form
      ref="formRef"
      v-loading="loading.openDrawer"
      :model="formData"
      class="wave-order_recordcode"
    >
      <div v-for="(formList, listIndex) in formData.form" :key="listIndex">
        <div class="collapse-button">
          <nh-button type="grey" @click="handleChangeActive(true, listIndex)">
            展开全部订单
          </nh-button>
          <nh-button type="grey" @click="handleChangeActive(false, listIndex)">
            收起全部订单
          </nh-button>
        </div>
        <div class="header">
          <p>车牌号：<span>{{ formList.plateNo }}</span></p>
          <p>线路编号：<span>{{ formList.lineId }}</span></p>
          <p>线路名称：<span>{{ formList.lineName }}</span></p>
        </div>
        <div class="body">
          <div :style="{width: totalWidth + 'px'}">
            <PlTable
              :columns="tableColumn"
              class="collapse-table-header"
            />
            <el-collapse
              v-if="formList.orders?.length > 0"
              v-model="activeNames[listIndex]"
            >
              <el-collapse-item
                v-for="(item, i) in formList.orders"
                :key="i"
                :name="i"
              >
                <template #title>
                  <div class="collapse-title">
                    <p
                      v-for="(list, indexH) in mergeHeade"
                      :key="list.prop"
                      :style="setStyleHeader(list)"
                    >
                      <template v-if="indexH === 0">
                        <i class="plus">-</i><i class="minus">+</i>
                      </template>
                      <span>{{ list.label }}</span><span>{{ item[list.prop] }}</span>
                    </p>
                  </div>
                </template>
                <div class="table-box">
                  <PlTable
                    v-if="item.details"
                    :data="item.details"
                    :columns="tableColumn"
                    :show-header="false"
                  >
                    <template #[PRACTICAL_SHIPMENT]="{row, $index}">
                      <el-form-item
                        v-if="$index >= 0"
                        :prop="
                          `form.${listIndex}.orders.${i}.details.${$index}.${PRACTICAL_SHIPMENT}`
                        "
                        :rules="[
                          { required: true, message: ' ', },
                        ]"
                      >
                        <el-row>
                          <el-col
                            v-if="row.conversionRate !==1 "
                            class="custom-input-number"
                            :span="12"
                          >
                            <!-- 暂不做回库任务，禁止编辑 -->
                            <el-input-number
                              v-model="row.bigDeliverNum"
                              :min="0"
                              :step="1"
                              :precision="0"
                              :step-strictly="true"
                              :controls="false"
                              @blur="handleChangeNumber"
                            />
                            <span>{{ row.bigUnit }}</span>
                          </el-col>
                          <el-col
                            class="custom-input-number"
                            :span="12"
                          >
                            <el-input-number
                              v-model="row.smallDeliverNum"
                              :min="0"
                              :step="0.001"
                              :step-strictly="true"
                              :precision="3"
                              :controls="false"
                              @blur="handleChangeNumber"
                            />
                            <span>{{ row.smallUnit }}</span>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </template>
                    <template #[ACTUAL_SUPPLEMENTARY]="{row, $index}">
                      <el-form-item
                        v-if="$index >= 0"
                        :prop="
                          `form.${listIndex}.orders.${i}.details.${$index}.${ACTUAL_SUPPLEMENTARY}`
                        "
                        :rules="[
                          { required: row.copyCode, message: ''},
                          {
                            validator: (rule, value, callback) =>
                              validateNotZero( callback, row)

                          }
                        ]"
                      >
                        <div class="custom-input-number">
                          <el-input
                            v-model="row[ACTUAL_SUPPLEMENTARY]"
                            :disabled="!row.copyCode"
                            @blur="handleChangeNumber"
                          />
                          <span>{{ row.auxiliaryUnit }}</span>
                        </div>
                      </el-form-item>
                    </template>
                  </PlTable>
                </div>
              </el-collapse-item>
            </el-collapse>
            <div v-else>
              <el-empty description="暂无数据" />
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <template #footer>
      <div class="footer">
        <div>
          <nh-button
            v-if="islessGoods"
            type="primary"
            :loading="loading.next"
            @click="handleToDepot"
          >
            下一步
          </nh-button>
          <nh-button
            v-else
            type="primary"
            :loading="loading.handleSubmit"
            @click="handleSubmit"
          >
            确认
          </nh-button>
          <nh-button
            @click="handleClose"
          >
            取消
          </nh-button>
        </div>
      </div>
    </template>
    <return-to-depot ref="returnDepot" @close="closeDown" />
  </nh-drawer>
</template>
<script setup>
import {
  ref, defineExpose, reactive, defineEmits, getCurrentInstance,
} from 'vue';
import unitNumber from '@/utils/unitNumber';
import { tableColumn, mergeHeade, totalWidth } from './fileds';
import { PRACTICAL_SHIPMENT, ACTUAL_SUPPLEMENTARY } from './constant';
import computeTotal from './use/computeTotal';
import setStyleHeader from './use/styleHeader';
import convertForm from './use/convertForm';
// import validateNotZero from './use/rule';
import verifyInt from './unit';
import ReturnToDepot from './ReturnToDepot.vue';
import {
  transportConfirmNew, manyGoodsBack, confirmDataVali, confirmDataValiBack,
} from './api';

const formData = ref({ form: [] });
const result = ref([]);
const formRef = ref('formRef');
const returnDepot = ref('returnDepot');
const drawerVisible = ref(false);
const islessGoods = ref(false);
const loading = reactive({
  openDrawer: false,
  handleSubmit: false,
  next: false,
});
const emit = defineEmits(['onSuccess']);
const openDrawer = async (data) => {
  drawerVisible.value = true;
  formData.value.form = [...data];
};
const instance = getCurrentInstance();
const news = instance.appContext.config.globalProperties;
const activeNames = ref([]);
const handleChangeActive = (bool, index) => {
  if (bool) {
    activeNames.value[index] = formData.value.form[index].orders.map((item, i) => i);
  } else {
    activeNames.value[index] = [];
  }
};
const validateNotZero = async (callback, row) => {
  if (result.value.length > 0) {
    result.value.forEach((i, index) => {
      if (i.deliverOrderDetailId === row.deliverOrderDetailId) {
        result.value.splice(index, 1);
        callback(new Error(''));
      }
    });
  } else {
    callback();
  }
};

const handleChangeNumber = () => {
  let flag = false; // 判断实发量是否小于发货量
  islessGoods.value = false;
  // 实发量大小单位转换小单位
  const form = formData.value.form.map((list) => {
    const orders = list.orders.map((item) => {
      const details = item.details.map((detail) => {
        let actualShippedAmount = unitNumber.getTotal({
          ...detail,
          bigUnitNumber: detail.bigDeliverNum || 0,
          smallUnitNumber: detail.smallDeliverNum || 0,
        });
        if (actualShippedAmount >= detail.allocateNum) {
          actualShippedAmount = detail.allocateNum;
        } else {
          flag = true;
        }
        const { bigUnitNumber, smallUnitNumber } = unitNumber.calculate({
          ...detail,
          smallUnitNumber: actualShippedAmount,
        });
        return {
          ...detail,
          actualShippedAmount,
          [ACTUAL_SUPPLEMENTARY]: detail[ACTUAL_SUPPLEMENTARY] === ''
            ? null : verifyInt(detail[ACTUAL_SUPPLEMENTARY] * 1000) / 1000,
          smallDeliverNum: smallUnitNumber,
          bigDeliverNum: bigUnitNumber,
        };
      });
      islessGoods.value = flag;
      return {
        ...item,
        details,
      };
    });
    return {
      ...list,
      orders,
    };
  });
  formData.value.form = computeTotal(form);
  // formRef.value.validate();
};
const handleToDepot = async () => {
  result.value = [];
  const data = convertForm(formData.value.form);
  result.value = await confirmDataValiBack(data);
  let str = '';
  result.value.forEach((i) => {
    if (i.errorMsg) str += i.errorMsg;
  });
  if (str) news.$message.error(str);
  await formRef.value.validate();
  loading.next = true;
  try {
    const DATA = await manyGoodsBack(data);
    returnDepot.value.openDrawer(DATA);
    loading.next = false;
  } finally {
    loading.next = false;
  }
};
const handleClose = () => {
  formRef.value.resetFields();
  formData.value = {
    form: [],
  };
  activeNames.value = [];
  drawerVisible.value = false;
};

const handleSubmit = async () => {
  const data = convertForm(formData.value.form);
  result.value = await confirmDataVali(data);// 验证接口
  let str = '';
  result.value.forEach((i) => {
    if (i.errorMsg) str += i.errorMsg;
  });
  if (str) news.$message.error(str);
  await formRef.value.validate();
  loading.handleSubmit = true;
  try {
    // await confirmDataVali(data);
    await transportConfirmNew(data);// 保存接口
    emit('onSuccess');
    loading.handleSubmit = false;
    handleClose();
  } finally {
    loading.handleSubmit = false;
  }
};

const closeDown = (num) => {
  if (num === 1) {
    drawerVisible.value = false;
    emit('onSuccess');
  }
};

defineExpose({
  openDrawer,
});
</script>
<style lang="scss" scoped>
.el-collapse-item {
  p {
    display: flex;
    align-items: center;

    i {
      font-style: normal;
      margin-right: 8px;
    }
  }

  .minus,
  .plus {
    width: 16px;
    height: 16px;
    line-height: 12px;
    font-size: 14px;
    font-weight: 800;
    text-align: center;
    border: 1px solid #cdcee0;
  }

  .minus {
    display: inline-block;
  }

  .plus {
    display: none;
  }
}

.custom-input-number {
  display: flex;
  align-items: center;

  span {
    padding: 0 8px;
    white-space: nowrap;
  }
}

.collapse-title {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 12px;
  background: #fff;
  box-shadow: 0 2px 8px 0  rgba(200, 201, 204, 0.5);

  & > p {
    height: 48px;
    padding-right: 8px;
    flex-shrink: 0;
    flex-grow: 0;

    span {
      line-height: 16px;
    }

    span:first-of-type {
      color: #167aa1;
      white-space: nowrap;
    }
  }
}

.wave-order_recordcode {
  .collapse-button {
    margin-bottom: 16px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    p {
      flex: 1;
    }
  }

  .body {
    width: 100%;
    overflow-x: auto;

    .el-form-item {
      margin: 0;
    }
  }
}

.el-collapse-item.is-active {
  .minus {
    display: none;
  }

  .plus {
    display: inline-block;
  }
}

.footer {
  padding: 0 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}

</style>
<style lang="scss">
.wave-order_recordcode {
  .el-radio.is-bordered {
    padding: 0 19px 0 11px;
  }

  .collapse-table-header {
    .el-table__empty-block {
      display: none;
    }
  }

  .el-collapse-item__arrow {
    display: none;
  }
}
</style>
