import unitNumber, { BIG_NUMBER } from '@/utils/unitNumber';
import { PRACTICAL_SHIPMENT, ACTUAL_SUPPLEMENTARY } from '../constant';

function getTotal(num1, num2) {
  return (Math.round((num1 || 0) * 1000) + Math.round((num2 || 0) * 1000)) / 1000;
}

/**
 * 计算当前订单的辅助单位数量的合计和波次总的抄码合计。
 * @param {*} formData
 * @returns formData
 */
export default function computeTotal(formData) {
  const form = formData.map((list) => {
    const data = list;
    data.orders = list.orders.map((item) => {
      const row = item;
      row[PRACTICAL_SHIPMENT] = 0;
      row[ACTUAL_SUPPLEMENTARY] = 0;

      const {
        bigDeliverNum, smallDeliverNum, shippedAuxiliaryAmount,
      } = row.details.reduce((prev, curr) => {
        const {
          bigUnitNumber,
          smallUnitNumber,
        } = unitNumber.calculate({
          ...curr,
          smallUnitNumber: curr[PRACTICAL_SHIPMENT],
        }, BIG_NUMBER);
        return {
          bigDeliverNum: getTotal(prev.bigDeliverNum, bigUnitNumber),
          smallDeliverNum: getTotal(prev.smallDeliverNum, smallUnitNumber),
          shippedAuxiliaryAmount: getTotal(
            prev.shippedAuxiliaryAmount, curr[ACTUAL_SUPPLEMENTARY],
          ),
        };
      }, {
        bigDeliverNum: 0,
        smallDeliverNum: 0,
        shippedAuxiliaryAmount: 0,
      });
      return {
        ...row,
        [PRACTICAL_SHIPMENT]: `${bigDeliverNum}整${smallDeliverNum}零`,
        [ACTUAL_SUPPLEMENTARY]: shippedAuxiliaryAmount,
      };
    });
    return {
      ...data,
    };
  });
  return form;
}
