<template>
  <pl-block>
    <PlForm
      v-model="searchForm"
      mode="search"
      :fields="serchFields"
      @submit="handleSearch"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @selection-change="handleSelect"
    >
      <template #tableHeaderRight>
        <nh-button
          type="primary"
          plain
          :track="{
            trackName: '出库管理/线路发运/待发运tab/[批量发运]'
          }"
          @click="handleBatchContinue"
        >
          批量发运
        </nh-button>
        <nh-button
          type="primary"
          plain
          :track="{
            trackName: '出库管理/线路发运/待发运tab/[打印送货单]'
          }"
          @click="handlePrint"
        >
          打印送货单
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button
          type="text"
          :loading="loading.handleContinue"
          @click="handleContinue(row)"
        >
          发运
        </nh-button>
        <nh-button
          type="text"
          @click="handlePreAudit(row)"
        >
          详情
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <!-- <el-dialog
    v-model="dialogFormVisible"
    v-loading="loading.handleContinue"
    title="发运确认"
    custom-class="custom-dialog_400"
  >
    <template v-if="shipConfirm.needSeal">
      <div class="dialog-info">
        <span><label>车牌号：</label> {{ shipConfirm.plateNo }}</span>
        <span><label>司机：</label>{{ shipConfirm.driverName }}</span>
      </div>
      <el-form :model="shipConfirm" label-position="left">
        <el-form-item
          v-if="shipConfirm.includeHandling"
          label="是否外包装卸:"
          label-width="120px"
          :rules="[
            {
              required: true,
              message: '请选择',
              trigger: ['blur','change'],
            },
          ]"
        >
          <el-select
            v-model="shipConfirm.outsourceHandling"
            placeholder="请选择"
          >
            <el-option
              v-for="item in URGENT"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="封箱签：" label-width="70px">
          <el-input v-model="shipConfirm.sealLabel" />
        </el-form-item>
      </el-form>
    </template>
    <template v-else>
      <p>{{ `是否确认车辆[${shipConfirm.plateNo}]已发运?` }}</p>
      <el-form :model="shipConfirm" label-position="left">
        <el-form-item
          v-if="shipConfirm.includeHandling"
          label="是否外包装卸:"
          label-width="120px"
          :rules="[
            {
              required: true,
              message: '请选择',
              trigger: ['blur','change'],
            },
          ]"
        >
          <el-select
            v-model="shipConfirm.outsourceHandling"
            placeholder="请选择"
          >
            <el-option
              v-for="item in URGENT"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <div class="dialog-footer">
        <nh-button @click="dialogCancel">
          取 消
        </nh-button>
        <nh-button
          v-loading="loading.dialogConfirm"
          type="primary"
          @click="dialogConfirm"
        >
          确 定
        </nh-button>
      </div>
    </template>
  </el-dialog> -->
  <nh-dialog
    v-model="dialogFormVisibleBatch"
    title="发运确认"
    custom-class="custom-dialog_400"
    :track="{
      name_zh: `出库管理/线路发运/待发运tab/弹窗-发运确认`,
      trackName: $route.path
    }"
  >
    <el-form :model="shipConfirm" label-position="left">
      <el-form-item
        v-if="branchIncludeHandling"
        label="是否外包装卸:"
        label-width="120px"
        :rules="[
          {
            required: true,
            message: '请选择',
            trigger: ['blur','change'],
          },
        ]"
      >
        <el-select
          v-model="shipConfirm.outsourceHandling"
          placeholder="请选择"
        >
          <el-option
            v-for="item in URGENT"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="封箱签:" label-width="70px">
        <el-input v-model="shipConfirm.sealLabel" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <nh-button @click="dialogCancelBatch">
          取 消
        </nh-button>
        <nh-button
          v-loading="loading.dialogConfirmBatch"
          type="primary"
          @click="dialogConfirmBatch"
        >
          确 定
        </nh-button>
      </div>
    </template>
  </nh-dialog>
  <pre-audit ref="preAudit" />
  <despatch ref="despatchDialog" @onSuccess="getTableData" />
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import { YMDHMS } from '@/constant/timeFormat';
import { PRE_LOADING, URGENT } from '../../fileds';
import { TABLECOLUMN, serchFields } from './fileds';
import { CONFIRM_TYPE } from '../../../Components/BatchConfirm/constant';
import {
  transportPage,
  // isNeedSealLabel,
  // transportConfirm,
  transportIds, transportConfirmNew,
} from '../../api';
import initDespatch from '../../../Components/BatchConfirm/use/init';
import PreAudit from '../../detail/index.vue';
import Despatch from '../../../Components/BatchConfirm/index.vue';

export default {
  name: 'PreAuditPending',
  components: {
    PreAudit,
    Despatch,
  },
  filters: {
    filterLabel(val, arr) {
      const findArr = arr && arr.find((item) => item.code === val);
      return findArr && findArr.label;
    },
  },
  mixins: [loadingMixin],
  provide: {
    nameZh: '待发运tab',
  },
  data() {
    return {
      TABLECOLUMN,
      serchFields,
      URGENT,
      tableData: [],
      searchForm: {
        shipStatus: PRE_LOADING.value,
      },
      transportIds: [],
      selectedList: [],
      dialogFormVisible: false,
      dialogFormVisibleBatch: false,
      shipConfirm: {
        outsourceHandling: true,
      },
      form: {},
      loading: {
        getTableData: false,
        handleContinue: false,
        dialogConfirm: false,
      },
    };
  },
  computed: {
    branchIncludeHandling() {
      return this.selectedList.some((item) => item.includeHandling);
    },
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleSearch(query) {
      const [beginBookingTime, endBookingTime] = query.bookingTime || [];

      this.searchForm = {
        ...this.searchForm, ...query, beginBookingTime, endBookingTime,
      };
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await transportPage(pagination, this.searchForm);
      this.pagination.total = result.total;
      this.tableData = result.records.map((item) => ({
        ...item,
        bookingTime: item.bookingTime && moment(item.bookingTime).format(YMDHMS),
        beginLoadingTime: item.beginLoadingTime && moment(item.beginLoadingTime).format(YMDHMS),
        deliverTime: item.deliverTime && moment(item.deliverTime).format(YMDHMS),
        shippingTime: item.deliverTime && moment(item.deliverTime).format(YMDHMS),
        deliverNum: `整箱:${item.deliverBigNum || 0}拆零:${item.deliverSmallNum || 0}`,
      }));
    },
    async handleContinue(row) {
      // const resp = await isNeedSealLabel({ deliverTransportId: row.id });
      // this.shipConfirm = { ...row, needSeal: resp, deliverTransportId: row.id };
      // this.dialogFormVisible = true;
      const data = [{
        deliverTransportId: row.id,
        deliverOrderIds: row.detailResp.deliverOrders.map((item) => item.id),
        confirmType: CONFIRM_TYPE.LINE,
      }];
      const form = await initDespatch(data);
      this.$refs.despatchDialog.openDrawer(form);
    },
    // 批量发运
    handleBatchContinue() {
      if (this.transportIds.length === 0) {
        this.$message({ type: 'warning', message: '请选择至少一条数据!' });
        return;
      }
      this.shipConfirm = {
        outsourceHandling: true,
      };
      this.dialogFormVisibleBatch = true;
    },
    // 详情
    handlePreAudit(row) {
      this.$refs.preAudit.init(row);
    },
    dialogCancelBatch() {
      this.dialogFormVisibleBatch = false;
      this.shipConfirm = {};
    },
    // dialogCancel() {
    //   this.dialogFormVisible = false;
    //   this.shipConfirm = {};
    // },
    async dialogConfirmBatch() {
      const data = this.selectedList.map((item) => ({
        deliverTransportId: item.id,
        deliverOrderIds: item.detailResp?.deliverOrders?.map((it) => (it.id)),
        sealLabel: this.shipConfirm.sealLabel,
        outsourceHandling: this.branchIncludeHandling ? this.shipConfirm.outsourceHandling : null,
      }));
      await transportConfirmNew(data);
      this.$message({
        message: '批量发运任务已提交',
        type: 'success',
      });
      this.getTableData();
      this.dialogCancelBatch();
    },
    // async dialogConfirm() {
    //   const data = {
    //     deliverTransportId: this.shipConfirm.deliverTransportId,
    //     deliverOrderIds: this.shipConfirm.detailResp?.deliverOrders?.map((item) => (item.id)),
    //     outsourceHandling: this.shipConfirm.outsourceHandling,
    //   };
    //   if (this.shipConfirm.needSeal) {
    //     if (!this.shipConfirm.sealLabel) {
    //       this.$message({
    //         message: '封箱签不能为空',
    //         type: 'warning',
    //       });
    //       return;
    //     }
    //     data.sealLabel = this.shipConfirm.sealLabel;
    //   }
    //   await transportConfirm(data);
    //   this.$message({
    //     message: '发运成功',
    //     type: 'success',
    //   });
    //   this.getTableData();
    //   this.dialogCancel();
    // },
    handleSelect(selection) {
      this.transportIds = selection.map((item) => item.id);
      this.selectedList = selection;
    },
    async handlePrint() {
      if (this.transportIds.length === 0) {
        return;
      }
      const resp = await transportIds({ transportIds: this.transportIds });

      const routeUrl = this.$router.resolve({
        path: '/print/delivery-list',
        query: { ids: resp },
      });
      window.open(routeUrl.href, '_blank');
    },
  },
};
</script>
<style scoped lang="scss">
.action {
  padding: 15px 0;
}

.dialog-info {
  padding-bottom: 16px;

  span {
    display: inline-block;
    width: 50%;

    label {
      display: inline-block;
      width: 70px;
    }
  }
}
</style>
