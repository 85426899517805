import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';

const DICTIONARY = {
};
export { DICTIONARY };
export const TABLECOLUMN = [
  {
    type: 'selection',
    width: 55,
  }, {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '提货单号',
    prop: 'ladingOrderNo',
    minWidth: 120,
  }, {
    label: '车牌号',
    prop: 'plateNo',
    minWidth: 120,
  }, {
    label: '司机',
    prop: 'driverName',
    minWidth: 80,
  }, {
    label: '线路编号',
    prop: 'lineId',
    minWidth: 80,
  }, {
    label: '线路名称',
    prop: 'lineName',
    minWidth: 80,
  },
  {
    label: '线路订单数',
    prop: 'lineOrderNum',
    minWidth: 100,
  }, {
    label: '预约总数量',
    prop: 'bookingBigNum',
    minWidth: 160,
    formatter: (row) => `${row.bookingBigNum}整${row.bookingSmallNum}零`,
  },
  {
    label: '已装车量',
    prop: 'deliverBigNum',
    minWidth: 160,
    formatter: (row) => `${row.deliverBigNum}整${row.deliverSmallNum}零`,
  }, {
    label: '本车装车量',
    prop: 'thisCarShippedBigUnitNum',
    minWidth: 160,
    formatter: (row) => `${row.thisCarShippedBigUnitNum}整${row.thisCarShippedSmallUnitNum}零`,
  }, {
    label: '预约提货时间',
    prop: 'bookingTime',
    ...DateTime,
  }, {
    label: '已装车订单数',
    prop: 'deliverOrderNum',
    minWidth: 160,
  }, {
    label: '已装车货品量',
    prop: 'deliverNum',
    minWidth: 180,
  }, {
    label: '备注',
    prop: 'remark',
    minWidth: 180,
  }, {
    label: '操作',
    prop: 'action',
    fixed: 'right',
    width: 120,
  }];
export const serchFields = [
  {
    label: '提货单号',
    prop: 'ladingOrderNo',
    component: FormType.INPUT,
  }, {
    label: '司机',
    prop: 'driverName',
    component: FormType.INPUT,
  }, {
    label: '车牌号',
    prop: 'plateNo',
    component: FormType.INPUT,
  }, {
    label: '线路编号',
    prop: 'lineId',
    component: FormType.INPUT,
  }, {
    label: '线路名称',
    prop: 'lineName',
    component: FormType.INPUT,
  }, {
    label: '预约提货时间',
    prop: 'bookingTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
    },
  },
];
