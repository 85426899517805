import unitNumber from '@/utils/unitNumber';
import { batchConfirmDetailQuery } from '../api';
import computeTotal from './computeTotal';
import { PRACTICAL_SHIPMENT, ACTUAL_SUPPLEMENTARY } from '../constant';

async function init(data) {
  const { confirmType } = data[0];
  const resp = await batchConfirmDetailQuery(data);
  const form = resp.map((list) => {
    const deliverOrderIds = [];
    const orders = list.orders.map((item) => {
      deliverOrderIds.push(item.deliverOrderId);
      const row = item;
      row.bookingNum = `${item.totalBigBookingNum}整${item.totalSmallBookingNum}零`;
      row.allocateNum = `${item.totalBigAllocateNum}整${item.totalSmallAllocateNum}零`;
      const details = row.details.map((detail) => {
        const allocateNum = unitNumber.getTotal({
          ...detail,
          smallUnitNumber: detail.smallAllocateAmount,
          bigUnitNumber: detail.bigAllocateAmount,
        });

        const numData = unitNumber.calculate({
          ...detail,
          smallUnitNumber: detail.actualDefaultShippedAmount,
        });
        return {
          ...detail,
          allocateNum,
          [PRACTICAL_SHIPMENT]: detail.actualDefaultShippedAmount, // 默认实发量等于分配量
          [ACTUAL_SUPPLEMENTARY]: detail.actualDefaultShippedAuxiliaryAmount,
          bigDeliverNum: detail.conversionRate === 1
            ? 0 : numData.bigUnitNumber,
          smallDeliverNum: detail.conversionRate === 1
            ? detail.actualDefaultShippedAmount : numData.smallUnitNumber,
        };
      });
      return { ...row, details };
    });
    return {
      ...list,
      confirmType,
      deliverOrderIds,
      orders,
    };
  });

  return computeTotal(form);
}
export default init;
