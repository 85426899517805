const DICTIONARY = {
  TEMPERATURE_LAYER: 'temperatureLayer',
  BOOKING_NUM: 'bookingNum',
  DELIVER_NUM: 'deliverNum',
  SHIPPED_NUM: 'shippedNum',
};
export { DICTIONARY };
export const ODD_TITLE = [{
  label: '出库单号：',
  keyword: 'deliverOrderNo',
}, {
  label: '外部单号：',
  keyword: 'externalOrderNo',
}, {
  label: '货主：',
  keyword: 'shipperName',
}, {
  label: '站点：',
  keyword: 'stationName',
}];
export const TABLECOLUMN = [{
  label: '货品编码',
  prop: 'goodsCode',
  width: 80,
}, {
  label: '货品名称',
  prop: 'goodsName',
  width: 80,
}, {
  label: '温层',
  prop: DICTIONARY.TEMPERATURE_LAYER,
  width: 80,
}, {
  label: '默认单位',
  prop: 'defaultUnit',
  width: 80,
}, {
  label: '换算率',
  prop: 'conversionRate',
  width: 80,
}, {
  label: '预约数量',
  prop: DICTIONARY.BOOKING_NUM,
  width: 80,
}, {
  label: '已装车量',
  prop: DICTIONARY.DELIVER_NUM,
  width: 80,
}, {
  label: '本车装车量',
  prop: DICTIONARY.SHIPPED_NUM,
  width: 80,
}];
