<template>
  <pl-block>
    <PlForm
      v-model="searchForm"
      mode="search"
      :fields="serchFields"
      @submit="handleSearch"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @selection-change="handleSelect"
    >
      <template #tableHeaderRight>
        <nh-button
          type="primary"
          plain
          :track="{
            trackName: '出库管理/线路发运/已发运tab/[打印送货单]'
          }"
          @click="handlePrint"
        >
          打印送货单
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button
          type="text"
          @click="handlePreAudit(row)"
        >
          详情
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <pre-audit ref="preAudit" />
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import { YMDHMS } from '@/constant/timeFormat';

import { SHIPPED } from '../../fileds';
import { TABLECOLUMN, serchFields } from './fileds';
import { transportPage, transportIds } from '../../api';
import PreAudit from '../../detail/index.vue';

export default {
  name: 'Pending',
  components: {
    PreAudit,
  },
  filters: {
    filterLabel(val, arr) {
      const findArr = arr && arr.find((item) => item.code === val);
      return findArr && findArr.label;
    },
  },
  mixins: [loadingMixin],
  provide: {
    nameZh: '已发运tab',
  },
  data() {
    return {
      TABLECOLUMN,
      serchFields,
      tableData: [],
      searchForm: {
        shipStatus: SHIPPED.value,
      },
      loading: {
        getTableData: false,
      },
      transportIds: [],
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleSearch(query) {
      const [beginBookingTime, endBookingTime] = query.bookingTime || [];
      const [beginDeliverTime, endDeliverTime] = query.deliverTime || [];
      this.searchForm = {
        ...this.searchForm,
        ...query,
        beginBookingTime,
        endBookingTime,
        beginDeliverTime,
        endDeliverTime,
      };
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await transportPage(pagination, this.searchForm);
      this.pagination.total = result.total;
      this.tableData = result.records.map((item) => {
        const { cars = {} } = item;
        return {
          ...item,
          ...cars,
          bookingTime: item.bookingTime && moment(item.bookingTime).format(YMDHMS),
          deliverTime: item.deliverTime && moment(item.deliverTime).format(YMDHMS),
          deliverNum: `整箱:${item.deliverBigNum || 0}拆零:${item.deliverSmallNum || 0}`,
        };
      });
    },
    handleSelect(selection) {
      this.transportIds = selection.map((item) => item.id);
    },
    async handlePrint() {
      if (this.transportIds.length === 0) {
        return;
      }
      const resp = await transportIds({ transportIds: this.transportIds });
      const routeUrl = this.$router.resolve({
        path: '/print/delivery-list',
        query: { ids: resp },
      });
      window.open(routeUrl.href, '_blank');
    },
    // 详情
    handlePreAudit(row) {
      this.$refs.preAudit.init(row);
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
