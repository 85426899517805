import unitNumber from '@/utils/unitNumber';
import moment from '@/utils/moment';
import { ACTUAL_SUPPLEMENTARY } from '../constant';
/**
 * 计算当前订单的辅助单位数量的合计和波次总的抄码合计。
 * @param {*} formData
 * @returns formData
 */
export default function convertForm(fromData) {
  const form = fromData.map((list) => {
    const {
      deliverTransportId,
      confirmType,
      deliverOrderIds,
    } = list;
    // 需要回库的货品
    const needReturnReqs = [];
    // 需要录入辅助数量
    const auxiliaryAmountReqs = [];

    list.orders.forEach((order) => {
      order.details.forEach((detail) => {
        const allocateNum = unitNumber.getTotal({
          ...detail,
          smallUnitNumber: detail.smallDeliverNum,
          bigUnitNumber: detail.bigDeliverNum,
        });
        auxiliaryAmountReqs.push({
          deliverOrderId: order.deliverOrderId,
          deliverOrderNo: order.deliverOrderNo,
          deliverOrderDetailId: detail.deliverOrderDetailId,
          goodsId: detail.goodsId,
          batchNo: detail.batchNo,
          goodsQuality: detail.goodsQuality,
          systemDefaultShippedAmount: detail.systemDefaultShippedAmount,
          actualShippedAmount: allocateNum,
          actualShippedAuxiliaryAmount: detail[ACTUAL_SUPPLEMENTARY],
          isCopyCode: detail.copyCode,
          shipperGoodsCode: detail.shipperGoodsCode,
          goodsName: detail.goodsName,
          shipperCode: detail.shipperCode,
          shipperName: detail.shipperName,
          productDate: moment.format(detail.productDate),
        });
      });
    });
    return {
      deliverTransportId,
      confirmType,
      deliverOrderIds,
      needReturnReqs,
      auxiliaryAmountReqs,
    };
  });
  return form;
}
