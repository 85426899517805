import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 批量发运确认详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18977
 */
export function batchConfirmDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transport/batch_confirm_detail/query', data);
}

/**
 * @description: 批量发运确认详情新接口
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18977
 */
export function batchConfirmDetailQuery(data) {
  return wmsPlusHttp.post('/warehouse_management_system/loading/deliver/batch_confirm_detail/query', data);
}
/**
 * @description: 批量发运确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17981
 */
export function transportConfirm(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transport/batch_confirm', data);
}

/**
 * @description: 批量发运确认新接口
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19279
 */
export function transportConfirmNew(data) {
  return wmsPlusHttp.post('/warehouse_management_system/loading/deliver/batch_confirm', data);
}

/**
 * @description: 多货回库页面
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19281
 */
export function manyGoodsBack(data) {
  return wmsPlusHttp.post('/warehouse_management_system/loading/deliver/difference_move_data/get', data);
}
/**
 * @description: 校验发运抄码填写是否正确
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19011
 */
export function confirmDataValidator(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transport/confirmData/validator', data);
}
/**
 * @description: 校验发运抄码填写是否正确新接口
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19277
 */
export function confirmDataVali(data) {
  return wmsPlusHttp.post('/warehouse_management_system/loading/deliver/confirm_data/validator', data);
}
/**
 * @description: 回库校验接口
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19583
 */
export function confirmDataValiBack(data) {
  return wmsPlusHttp.post('/warehouse_management_system/loading/deliver/difference_move_data/validator', data);
}
// 获取仓库库位
//  https://yapi.ops.yunlizhi.cn/project/388/interface/api/12022
export function WarehouseLocation(data) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/page', data);
}
