const DICTIONARIES = {
};
// PRE_LOADING::待装车，PICK_COMPLETED:可发运,LOADING:装车中,SHIPPED:已发运
const PRE_LOADING = {
  label: '待发运',
  value: ['LOADING', 'PICK_COMPLETED', 'PRE_LOADING'],
};
const SHIPPED = {
  label: '已发运',
  value: ['SHIPPED'],
};
const URGENT = [
  {
    label: '是',
    value: true,
  },
  {
    label: '否',
    value: false,
  },
];
const TABS = [PRE_LOADING, SHIPPED];
export {
  DICTIONARIES,
  PRE_LOADING,
  SHIPPED,
  TABS,
  URGENT,
};
