import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 线路发运分页
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12232
 */
export function transportPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/transport/page', data, {
    params,
  });
}
/**
 * @description: 判断是否需要封箱签
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12266
 */
export function isNeedSealLabel(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transport/is_need_seal_label', data);
}
/**
 * @description: 发运确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12276
 */
export function transportConfirm(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transport/confirm', data);
}

/**
 * @description: 批量打印查询出库单id
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12336
 */
export function transportIds(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transport/deliver_order_ids/by/transport_ids', data);
}
/**
 * @description: 批量发运确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17981
 */
export function transportBatchConfirm(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transport/batch_confirm', data);
}

/**
 * @description: 批量发运确认新接口
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19279
 */
export function transportConfirmNew(data) {
  return wmsPlusHttp.post('/warehouse_management_system/loading/deliver/batch_confirm', data);
}
