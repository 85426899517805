import unitNumber from '@/utils/unitNumber';
import moment from '@/utils/moment';
import {
  PRACTICAL_SHIPMENT,
  ACTUAL_SUPPLEMENTARY,
  GO_BACK_STORAGE,
} from './constant';

const tableColumn = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  width: 160,
}, {
  label: '商品名称',
  prop: 'goodsName',
  width: 160,
}, {
  label: '货主',
  prop: 'shipperName',
  width: 100,
}, {
  label: '批次号',
  prop: 'batchNo',
  width: 100,
}, {
  label: '生产日期',
  prop: 'productDate',
  width: 140,
  formatter: ({ productDate }) => moment.format(productDate),
},
{
  label: '预约总数量',
  prop: 'bookingNum',
  width: 120,
  formatter: (row) => unitNumber.calculate({
    ...row,
    bigUnitNumber: row.bigBookingAmount || 0,
    smallUnitNumber: row.smallBookingAmount || 0,
  }).txt,
},
{
  label: '分配量',
  prop: 'allocateNum',
  width: 120,
  formatter: (row) => unitNumber.calculate({
    ...row,
    bigUnitNumber: row.bigAllocateAmount || 0,
    smallUnitNumber: row.smallAllocateAmount || 0,
  }).txt,
},
{
  label: '辅助单位数量',
  prop: 'bookingAuxiliaryAmount',
  width: 120,
  formatter: (row) => `${row.bookingAuxiliaryAmount}${row.auxiliaryUnit || ''}`,
},
{
  label: '已装车量',
  prop: 'loadingAmount',
  width: 100,
},
{
  label: '实发量',
  prop: PRACTICAL_SHIPMENT,
  width: 240,
},
{
  label: '实发辅助单位数量',
  prop: ACTUAL_SUPPLEMENTARY,
  width: 150,
}];
const mergeHeade = [{
  prop: 'deliverOrder',
  label: '装车顺序：',
  width: '210px',
}, {
  prop: 'stationName',
  label: '站点名称：',
}, {
  label: '合计：',
  width: '140px',
}, {
  prop: 'bookingNum',
  width: '120px',
}, {
  prop: 'allocateNum',
  width: '120px',
}, {
  prop: 'totalBookingAuxiliaryNum',
  width: '120px',
}, {
  prop: PRACTICAL_SHIPMENT,
  width: '240px',
}, {
  prop: ACTUAL_SUPPLEMENTARY,
  width: '150px',
}];
const totalWidth = tableColumn.reduce((prev, curr) => prev + (curr.width || 0), 0);
const goBackTabelColumn = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 160,
}, {
  label: '商品名称',
  prop: 'goodsName',
  minWidth: 150,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 160,
}, {
  label: '批次号',
  prop: 'batchNo',
  minWidth: 150,
}, {
  label: '生产日期',
  prop: 'productDate',
  minWidth: 120,
}, {
  label: '多货数量',
  prop: 'differenceAmount',
  minWidth: 80,
}, {
  label: '拣货库位',
  prop: 'locationCodes',
  minWidth: 150,
  formatter: (row) => row.locationCodes.map((i) => i.locationCode).join(','),
}, {
  label: '实际回库库位',
  prop: GO_BACK_STORAGE,
  minWidth: 150,
}];

export {
  tableColumn,
  mergeHeade,
  totalWidth,
  goBackTabelColumn,
};
