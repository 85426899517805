<template>
  <div class="flex_column full-content">
    <el-collapse v-model="activeNames" accordion>
      <el-collapse-item
        v-for="(list, index) in dataList"
        :key="index"
        :name="index"
      >
        <template #title>
          <ul class="collapse-title">
            <li v-for="item in ODD_TITLE" :key="item.keyword">
              <span>{{ item.label }}</span>
              <span>{{ list[item.keyword] }}</span>
            </li>
          </ul>
        </template>
        <el-table :data="list.deliverOrderDetails" class="table-component">
          <el-table-column type="index" width="50" />
          <el-table-column
            v-for="item in TABLECOLUMN"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :min-width="item.width"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <span v-if="item.prop === DICTIONARY.TEMPERATURE_LAYER">{{
                filterLabel(scope.row[item.prop], zoneTemperature)
              }}</span>
              <span v-else-if="handleNeedCinvert(item.prop)">{{
                convertUnit(scope.row[item.prop], scope.row)
              }}</span>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import unitNumber from '@/utils/unitNumber';
import mixin from '../../mixin';

import { ODD_TITLE, DICTIONARY, TABLECOLUMN } from './fileds';

export default {
  name: 'PreAuditLoadedWagons',
  mixins: [loadingMixin, mixin],
  props: {
    dataList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      ODD_TITLE,
      DICTIONARY,
      TABLECOLUMN,
      activeNames: 0,
      lists: [],
      loading: {
        getTableData: false,
      },
    };
  },
  methods: {
    handleNeedCinvert(prop) {
      return prop === DICTIONARY.BOOKING_NUM
      || prop === DICTIONARY.DELIVER_NUM
      || prop === DICTIONARY.SHIPPED_NUM;
    },
    filterLabel(val, arr) {
      const filterAttr = arr && arr.find((item) => item.value === val);
      return filterAttr ? filterAttr.label : '';
    },
    convertUnit(val, row) {
      return unitNumber.calculate({ ...row, smallUnitNumber: val }).txt;
    },
  },
};
</script>
<style scoped lang="scss">
.action {
  padding: 15px 0;
}

.collapse-title {
  display: flex;
  flex: 1;

  li {
    display: flex;
    flex: 1;

    &:not(:last-of-type) {
      margin-right: 16px;
    }

    span {
      white-space: nowrap;
    }
  }
}
</style>
