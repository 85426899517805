const PICKUP_TYPE = [{
  value: 'CUSTOMER_DELIVERY',
  label: '客户自提',
}, {
  value: 'TMS_DELIVERY',
  label: 'TMS配送',
}];
const BASE = [{
  label: '提货单号：',
  prop: 'ladingOrderNo',
}, {
  label: '车牌号：',
  prop: 'plateNo',
}, {
  label: '司机姓名：',
  prop: 'driverName',
}, {
  label: '提货方式：',
  prop: 'pickupType',
}, {
  label: '预约提货时间：',
  prop: 'bookingTime',
}, {
  label: '开始装车时间：',
  prop: 'beginLoadingTime',
}, {
  label: '装车完成时间：',
  prop: 'endLoadingTime',
}, {
  label: '发运时间：',
  prop: 'deliverTime',
}];
export {
  PICKUP_TYPE, BASE,
};
