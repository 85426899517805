<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="发运-多货回库"
    size="1111px"
    :append-to-body="true"
  >
    <el-form
      ref="formRef"
      :model="formData"
    >
      <div class="body">
        <div class="table-box">
          <PlTable
            :data="formData.needReturnReqs"
            :columns="goBackTabelColumn"
          >
            <template #[GO_BACK_STORAGE]="{row, $index}">
              <el-form-item
                v-if="$index >= 0"
                :prop="`needReturnReqs.${$index}.${GO_BACK_STORAGE}`"
                :rules="[
                  { required: true, message: ' ', trigger: 'blur' },
                ]"
              >
                <el-row>
                  <el-col>
                    <el-select
                      v-model="row.actualLocationCode"
                      filterable
                      :remote="true"
                      reserve-keyword
                      placeholder="请选择当前仓库库位"
                      :remote-method="remoteMethod"
                      loading-text="加载中……"
                      @change="getActualLocationCode($event,row,$index)"
                    >
                      <el-option
                        v-for="(item, index) in options"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </template>
          </PlTable>
        </div>
      </div>
    </el-form>
    <template #footer>
      <div class="footer">
        <div>
          <nh-button
            type="primary"
            @click="handleBack"
          >
            上一步
          </nh-button>
          <nh-button
            type="primary"
            @click="handleSubmit"
          >
            确认
          </nh-button>
          <nh-button
            @click="handleClose"
          >
            取消
          </nh-button>
        </div>
      </div>
    </template>
  </nh-drawer>
</template>
<script setup>
import {
  ref, defineExpose, defineEmits,
} from 'vue';
import { useStore } from 'vuex';
// import { key } from '@/store/index';
import { goBackTabelColumn } from './fileds';
import { GO_BACK_STORAGE } from './constant';
import { WarehouseLocation, transportConfirmNew } from './api';

const emit = defineEmits(['close']);

const formData = ref({});
const options = ref([]);
const formRef = ref('formRef');
const drawerVisible = ref(false);
const store = useStore();

const openDrawer = async (data) => {
  drawerVisible.value = true;
  const DATA = data;
  DATA[0].needReturnReqs = DATA[0].needReturnReqs.map(((item) => ({
    ...item,
    actualLocationCode: item.locationCodes.length === 1 ? item.locationCodes[0].locationCode : '',
    actualLocationId: item.locationCodes.length === 1 ? item.locationCodes[0].locationId : '',
  })));
  formData.value = DATA[0] || {};
};
const handleClose = () => {
  formRef.value.resetFields();
  drawerVisible.value = false;
};
const handleSubmit = async () => {
  await formRef.value.validate();
  await transportConfirmNew([formData.value]);
  handleClose();
  emit('close', 1);
};
const handleBack = async () => {
  handleClose();
};

const getActualLocationCode = (e, row, index) => {
  const target = options.value.filter((i) => i.value === e);
  const obj = row;
  obj.actualLocationId = target[0].id;
  formData.value[index] = obj;
};

const remoteMethod = (query) => {
  options.value = [];
  if (query !== '') {
    const params = {
      warehouseCode: store.state.user.accountInfo.ext.warehouseCode,
      storageLocationCode: query,
      page: 1,
      pageSize: 10,
    };
    WarehouseLocation(params)
      .then((resp) => {
        if (resp.records.length <= 0) {
          this.$message.error('该仓库下暂无库位');
          return;
        }
        resp.records.forEach((item) => {
          options.value.push({
            label: item.storageLocationCode,
            value: item.storageLocationCode,
            id: item.id,
          });
        });
      })
      .catch(({ resp }) => {
        resp.records.forEach((item) => {
          options.value.push({
            label: item.storageLocationCode,
            value: item.storageLocationCode,
            id: item.id,
          });
        });
      });
  } else {
    options.value = [];
  }
};

defineExpose({
  openDrawer,
});
</script>
<style lang="scss" scoped>
.wave-order_recordcode {
  .collapse-button {
    margin-bottom: 16px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    p {
      flex: 1;
    }
  }

  .body {
    width: 100%;
    overflow-x: auto;

    .el-form-item {
      margin: 0;
    }
  }
}

.custom-input-number {
  display: flex;
  align-items: center;

  span {
    padding: 0 8px;
    white-space: nowrap;
  }
}

.footer {
  padding: 0 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>
<style lang="scss">
.wave-order_recordcode {
  .el-radio.is-bordered {
    padding: 0 19px 0 11px;
  }

  .collapse-table-header {
    .el-table__empty-block {
      display: none;
    }
  }

  .el-collapse-item__arrow {
    display: none;
  }
}
</style>
