const PRACTICAL_SHIPMENT = 'actualShippedAmount'; // 实发量
const ACTUAL_SUPPLEMENTARY = 'actualShippedAuxiliaryAmount'; // 辅助数量
const GO_BACK_STORAGE = 'actualLocationCode'; // 实际回库库位
// 发运确认类型
const CONFIRM_TYPE = {
  LINE: 'LINE', // 按线路发运
  ORDER: 'ORDER', // 按订单发运
};
export {
  PRACTICAL_SHIPMENT,
  ACTUAL_SUPPLEMENTARY,
  GO_BACK_STORAGE,
  CONFIRM_TYPE,
};
