export default ((item) => {
  if (item.width) {
    return {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: item.width,
    };
  }
  return {
    flex: 1,
  };
});
