<template>
  <div class="list-container-component page">
    <nh-container class="flex_column buttom-box">
      <div class="flex_column full-content">
        <el-tabs v-model="activeName" class="flex_column buttom-box">
          <el-tab-pane
            :label="PRE_LOADING.label"
            :name="PRE_LOADING.label"
            class="buttom-box"
          >
            <pending-com v-if="activeName === PRE_LOADING.label" />
          </el-tab-pane>
          <el-tab-pane
            :label="SHIPPED.label"
            :name="SHIPPED.label"
            class="buttom-box"
          >
            <already-com v-if="activeName === SHIPPED.label" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </nh-container>
  </div>
</template>

<script>
import AlreadyCom from './components/already/index.vue';
import PendingCom from './components/pending/index.vue';
import { PRE_LOADING, SHIPPED } from './fileds';

export default {
  name: 'LineDelivery',
  components: {
    AlreadyCom,
    PendingCom,
  },
  data() {
    return {
      PRE_LOADING,
      SHIPPED,
      activeName: PRE_LOADING.label,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.page {
  :deep(.el-tabs__content) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.list-container-component {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.buttom-box {
  flex: 1;

  :deep(.buttom-box) {
    flex: 1;
  }
}

.base-label {
  color: #606266;
}

.base-value {
  font-weight: 600;
  color: #171717;
}

.base {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;

  & > div {
    width: 20%;
    min-width: 140px;
    padding: 8px 16px;
  }
}

.create_base {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;

  & > div {
    box-sizing: border-box;
    width: 50%;
    padding: 8px 16px;
  }
}
</style>
