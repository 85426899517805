<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="详情"
    size="1111px"
    :track="{
      name_zh: `出库管理/线路发运/${nameZh}/抽屉-详情`,
      trackName: $route.path
    }"
  >
    <nh-dialog-form>
      <nh-dialog-form-item
        v-for="item in BASE"
        :key="item.prop"
        :label="item.label"
        :span="8"
      >
        {{ baseData[item.prop] }}
      </nh-dialog-form-item>
    </nh-dialog-form>
    <el-tabs v-model="activeName" class="flex_column buttom-box">
      <el-tab-pane
        label="装车清单"
        name="first"
        class="buttom-box"
      >
        <loaded-wagons :data-list="deliverOrders" />
      </el-tab-pane>
    </el-tabs>
  </nh-drawer>
</template>

<script>
import moment from '@/utils/moment';
import { BASE, PICKUP_TYPE } from './fileds';
import LoadedWagons from './components/loadedWagons/index.vue';

export default {
  name: 'PreAuditDetail',
  components: {
    LoadedWagons,
  },
  inject: ['nameZh'],
  emits: ['onSuccess'],
  data() {
    return {
      drawerVisible: false,
      BASE,
      PICKUP_TYPE,
      id: null,
      activeName: 'first',
      baseData: {},
      deliverOrders: [],
    };
  },
  methods: {
    getLabel(val, arr) {
      const findAttr = arr && arr.find((item) => item.value === val);
      return findAttr && findAttr.label;
    },
    async init({ plateNo, detailResp }) {
      this.drawerVisible = true;
      this.id = plateNo;
      const { deliverOrders = [], ...baseData } = detailResp;
      this.deliverOrders = deliverOrders;
      this.baseData = {
        ...baseData,
        pickupType: this.getLabel(baseData.pickupType, PICKUP_TYPE),
        bookingTime: moment.format(baseData.bookingTime),
        beginLoadingTime: moment.format(baseData.beginLoadingTime),
        endLoadingTime: moment.format(baseData.endLoadingTime),
        deliverTime: moment.format(baseData.deliverTime),
      };
    },
    handleClose() {
      this.$emit('onSuccess');
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.page {
  :deep(.el-tabs__content) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.list-container-component {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.buttom-box {
  flex: 1;
}
</style>
